<!--
 * @Author: 王昌浩
 * @Date: 2020-07-30 15:32:13
 * @LastEditTime: 2020-10-26 17:40:05
 * @LastEditors: Please set LastEditors
 * @Description: 设备工单详情
 * @FilePath: \trunk\src\pages\equipmentState\aomOrder\aomOrderDetail.vue
-->
<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button
        class="right"
        type="primary"
        style="width: 88px; height: 36px;"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <div class="baseInfo">
        <TitleModule :title="'基本信息'"></TitleModule>
        <info-table
          :dataValue="detail"
          :nameStyle="{ width: '150px' }"
          :valueStyle="{
            width: 'calc(100% - 150px)',
          }"
          :num="3"
          :tableData="tableData"
        ></info-table>
      </div>
      <div class="auditRecord">
        <TitleModule :title="'状态信息'"></TitleModule>
        <info-table
          :nameStyle="{ width: '150px' }"
          :valueStyle="{
            width: 'calc(100% - 150px)',
          }"
          :num="3"
          :dataValue="detail"
          :tableData="auditRecordTable"
        ></info-table>
      </div>
      <div class="auditHandle">
        <TitleModule :title="'原因'"></TitleModule>
        <info-table
          :dataValue="detail"
          :nameStyle="{ width: '150px' }"
          :valueStyle="{ width: 'calc(100% - 150px)' }"
          :num="3"
          :tableData="auditHandleTable"
        >
        </info-table>
      </div>
    </div>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable/infoTable";
import TitleModule from "@/components/titleModule/titleModule";
export default {
  components: { infoTable, TitleModule },
  data() {
    return {
      tableData: [
        {
          name: "事件编号",
          key: "orderCode",
        },
        {
          name: "工单设备类型",
          key: "equipType",
        },
        {
          name: "区域",
          key: "area",
        },
        {
          name: "所属父车场",
          key: "parentParkIdName",
        },
        {
          name: "所属子车场",
          key: "parkName",
        },
        {
          name: "泊位号",
          key: "berthCoeds",
        },
        {
          name: "上报故障类型",
          key: "faultTypeName",
        },
        {
          name: "",
          key: "",
        },
        {
          name: "",
        },
      ],
      auditRecordTable: [
        {
          name: "当前状态",
          key: "state",
        },
        {
          name: "新建时间",
          key: "reportTime",
        },
        {
          name: "派工时间",
          key: "createdTime",
        },
        {
          name: "搁置时间",
          key: "shelveTime",
        },
        {
          name: "完工时间",
          key: "finishTime",
        },
        {
          name: "",
        },
      ],
      auditHandleTable: [
        {
          name: "实际故障类型",
          key: "finishFaultDesc",
        },
        {
          name: "搁置原因",
          key: "shelveDesc",
        },
        {
          name: "",
        },
      ],
      detail: {},
    };
  },
  // 方法集合
  methods: {
    getDetails() {
      let url = "/acb/2.0/aomEquOrder/detail/" + this.$route.query.orderId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (
            res.value.faultTypeName == "离线" &&
            res.value.equipType == "车场"
          ) {
            this.detail = res.value;
            this.tableData[5].html = "全部泊位";
          } else {
            this.detail = res.value;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getDetails();
  },
};
</script>
<style lang="stylus" scoped>
// @import url(); 引入公共css类
.baseInfo,.auditRecord,.auditHandle
  margin-bottom 20px
</style>
